<template>
  <div class="about">
    <div class="nav">
      <router-link to="/about/a">A链接</router-link>
      <router-link to="/b">B链接</router-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.router-link-exact-active {
  color: #42b983;
}
.router-link-active {
  color: #42b983;
}
</style>
